import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import headerImage from "../images/about_bg.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "10rem 16px 0",
    height: "60vh",
    textAlign: "center",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      display="flex"
      flexDirection={"column"}
      sx={{ width: "100%" }}
      alignContent={"center"}
    >
      <Grid item sx={{ width: "100%" }}>
        <Box className={classes.header}>
          <Grid
            container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="h3"
                sx={{ color: "white", fontWeight: "bold!important" }}
              >
                ABOUT US
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item display={"flex"} alignItems={"center"}>
        <Box className={classes.contentSection}>
          <Grid container>
            <Grid item p={4}>
              <Typography variant="h3" className={classes.sectionTitle}>
                HOW WE WIN:
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                Our expertise spans decades of winning races. Yes, our team has
                earned awards throughout the years. But our true value is in our
                effective, customized campaign strategies. We know how to win
                races today, you need more than a traditional marketing
                approach.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 16 }}
              >
                YOU NEED MESSAGES DESIGNED TO GRAB AND HOLD THE ATTENTION OF
                VOTERS — <b>WE DO JUST THAT.</b>
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                We guide candidates across the country in winning races ranging
                from City Council to the United States Senate, and every step
                between. We know every campaign is unique. Our services are
                fully customizable and tailored to fit your campaign’s needs.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 16 }}
              >
                TRUBLU POLITICS HAS A STRONG RECORD OF WINNING THE TOUGHEST
                POLITICAL RACES.
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                In 2008, we catapulted Donna Edwards to victory over a 16-year
                incumbent and led Frank Kratovil to a win within a district
                where President Obama won only 41 percent of the vote.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                In 2014, we produced a statewide independent expenditure
                campaign that propelled Brian Frosh to a resounding
                come-from-behind victory as Maryland’s new Attorney General.
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                We developed direct mail for SEIU Local 1199 that was successful
                in winning 10 out of 11 contested legislative races.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                In 2018, we produced direct mail that was pivotal in the
                election of Michael O’Connor, the first Democratic Mayor of
                Frederick in 12 years. Our mail also assisted in successful
                Democratic State Senate primaries for Clarence Lam and Obie
                Patterson, county primaries for Mel Franklin and Deb Jung, and
                House of Delegates Primaries for Ariana Kelly, Jim Gilchrist,
                Kirill Reznik, Terri Hill and Eric Ebersole. Our independent
                expenditure mail propelled Marc Elrich to win the Democratic
                nomination for County Executive in Montgomery County.
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                We win because we base our approach on diligent research and
                shape our strategies to appeal to your voters. Our concise,
                impactful messages resonate with voters and inspire them to act.
                Our award-winning creative cuts through the clutter and stands
                out in the mailbox. We have developed and directed 100+
                political campaigns, working to navigate candidates through the
                toughest of districts.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default About;
