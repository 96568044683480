import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import logo from "../images/header-logo.png";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "white!important",
    color: "#000",
    boxShadow: "none",
    position: "absolute!important",
    width: "100%",
  },
  logo: {
    height: "79px",
    marginRight: theme.spacing(2),
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  menuButton: {
    marginLeft: theme.spacing(3),
  },
  link: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    fontFamily: "'Montserrat'",
    fontSize: "1rem",
    margin: "0 .5rem"
  },
  toolbar: {
    justifyContent: "space-between",
  },
  menuItem: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  drawerList: {
    width: 250,
  },
  menuIcon: {
    color: theme.palette.primary.main,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerList = () => (
    <div
      className={classes.drawerList}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {["HOME", "ABOUT", "PORTFOLIO", "CLIENTS", "OUR TEAM", "CONTACT"].map(
          (text, index) => (
            <ListItemButton
              key={text}
              component={Link}
              to={`/${text.toLowerCase().replace(" ", "")}`}
            >
              <ListItemText primary={text} />
            </ListItemButton>
          )
        )}
      </List>
    </div>
  );

  return (
    <AppBar
      className={classes.appBar}
      sx={{ background: "transparent", py: 1 }}
    >
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center" component={Link} to="/">
          <img src={logo} alt="TruBlu Politics Logo" className={classes.logo} />
        </Box>
        <Box display={{ xs: "none", md: "flex" }}>
          <Button className={classes.menuButton}>
            <Link to="/" className={`${classes.link} ${classes.menuItem}`}>
              HOME
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link to="/about" className={`${classes.link} ${classes.menuItem}`}>
              ABOUT
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link
              to="/portfolio"
              className={`${classes.link} ${classes.menuItem}`}
            >
              PORTFOLIO
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link
              to="/clients"
              className={`${classes.link} ${classes.menuItem}`}
            >
              CLIENTS
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link
              to="/ourteam"
              className={`${classes.link} ${classes.menuItem}`}
            >
              OUR TEAM
            </Link>
          </Button>
          <Button className={classes.menuButton}>
            <Link
              to="/contact"
              className={`${classes.link} ${classes.menuItem}`}
            >
              CONTACT
            </Link>
          </Button>
        </Box>
        <Box display={{ xs: "flex", md: "none" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
