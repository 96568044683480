import React from "react";
import { Grid, Typography, Box, Avatar } from "@mui/material";
import { Link } from "react-router-dom"; // Change import to use Link from react-router-dom
import { makeStyles } from "@mui/styles";
import headerImage from "../images/about_bg.jpg";
import artemisImage from "../images/artemis-large.jpg";
import tapperImage from "../images/tapper-lg.jpg";
import davidImage from "../images/davidg-large.jpg";
import julieImage from "../images/julie-large.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "10rem 16px 0",
    height: "60vh",
    textAlign: "center",
  },
  contentSection: {
    textAlign: "center",
    padding: theme.spacing(8, 2),
  },
  teamMember: {
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  avatar: {
    width: "295px!important",
    height: "222px!important",
    margin: "auto",
    marginBottom: "16px",
  },
  name: {
    color: "#00afef !important",
    fontWeight: "bold !important",
    fontFamily: "Montserrat, sans-serif !important",
    textDecoration: "none !important",
  },
  position: {
    color: theme.palette.text.secondary,
  },
}));

const OurTeam = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.header}>
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item>
            <Typography
              variant="h3"
              sx={{ color: "white", fontWeight: "bold!important" }}
            >
              OUR TEAM
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.contentSection}>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          className={classes.gridContainer}
        >
          <Grid container display="flex" justifyContent="center">
            <Grid item xs={12} sm={6} md={3} py={4} className={classes.teamMember}>
              <Link to="/team/david-goodman" underline="none">
                <Avatar
                  variant="square"
                  alt="DAVID GOODMAN"
                  src={davidImage}
                  className={classes.avatar}
                />
                <Typography variant="h6" className={classes.name}>
                  DAVID GOODMAN
                </Typography>
                <Typography variant="body2" className={classes.position}>
                  President
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3} py={4} className={classes.teamMember}>
              <Link to="/team/michael-tapper" underline="none">
                <Avatar
                  variant="square"
                  alt="MICHAEL TAPPER"
                  src={tapperImage}
                  className={classes.avatar}
                />
                <Typography variant="h6" className={classes.name}>
                  MICHAEL TAPPER
                </Typography>
                <Typography variant="body2" className={classes.position}>
                  CEO
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container display="flex" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={6}
              md={3} py={4}
              className={classes.teamMember}
            >
              <Link to="/team/julie-jakopic" underline="none">
                <Avatar
                  variant="square"
                  alt="JULIE JAKOPIC"
                  src={julieImage}
                  className={classes.avatar}
                />
                <Typography variant="h6" className={classes.name}>
                  JULIE JAKOPIC
                </Typography>
                <Typography variant="body2" className={classes.position}>
                  Senior Advisor
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3} py={4}
              className={classes.teamMember}
            >
              <Link to="/team/artemis-queyquep" underline="none">
                <Avatar
                  variant="square"
                  alt="ARTEMIS QUEYQUEP"
                  src={artemisImage}
                  className={classes.avatar}
                />
                <Typography variant="h6" className={classes.name}>
                  ARTEMIS QUEYQUEP
                </Typography>
                <Typography variant="body2" className={classes.position}>
                  Partner
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default OurTeam;
