import React, { useState } from "react";
import { Grid, Typography, Box, Modal, Backdrop, Fade, Button, IconButton, MobileStepper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import headerImage from "../images/about_bg.jpg";

// Sample images (replace with your actual image paths)
import obiePattersonImage from "../images/portfolio-images/obie-patterson.jpg";
import firefightersImage1 from "../images/portfolio-images/firefighters1.jpg";
import firefightersImage2 from "../images/portfolio-images/firefighters2.jpg";
import debJungImage1 from "../images/portfolio-images/deb-jung1.jpg";
import debJungImage2 from "../images/portfolio-images/deb-jung2.jpg";
import arianaKellyImage1 from "../images/portfolio-images/ariana-kelly1.jpg";
import arianaKellyImage2 from "../images/portfolio-images/ariana-kelly2.jpg";
import seiuImage1 from "../images/portfolio-images/seiuImage1.jpg";
import seiuImage2 from "../images/portfolio-images/seiuImage2.jpg";
import brianFroshImage1 from "../images/portfolio-images/brian-frosh1.jpg";
import brianFroshImage2 from "../images/portfolio-images/brian-frosh2.jpg";
import marylandDemImage1 from "../images/portfolio-images/maryland-dem1.jpg";
import marylandDemImage2 from "../images/portfolio-images/maryland-dem2.jpg";
import donnaEdwardsImage1 from "../images/portfolio-images/donna-edwards1.jpg";
import donnaEdwardsImage2 from "../images/portfolio-images/donna-edwards2.jpg";
import barbaraMikulskiImage1 from "../images/portfolio-images/barbara-mikulski1.jpg";
import barbaraMikulskiImage2 from "../images/portfolio-images/barbara-mikulski2.jpg";
import frankKratovilImage1 from "../images/portfolio-images/frank-kratovil1.jpg";
import frankKratovilImage2 from "../images/portfolio-images/frank-kratovil2.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "10rem 16px 0",
    height: "60vh",
    textAlign: "center",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
    cursor: "pointer",
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
    outline: 'none',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    maxWidth: '100%',
    maxHeight: '80vh',
  },
  stepper: {
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(2, 0),
  },
}));

const Portfolio = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [images, setImages] = useState([]);

  const handleOpen = (imageArray) => {
    setImages(imageArray);
    setActiveStep(0); // reset to the first image
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Grid
      container
      display="flex"
      flexDirection={"column"}
      sx={{ width: "100%" }}
      alignContent={"center"}
    >
      <Grid item sx={{ width: "100%" }}>
        <Box className={classes.header}>
          <Grid
            container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="h3"
                sx={{ color: "white", fontWeight: "bold!important" }}
              >
                PORTFOLIO
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item display={"flex"} alignItems={"center"}>
        <Box className={classes.contentSection}>
          <Grid container>
            <Grid item p={4}>
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                onClick={() => handleOpen([obiePattersonImage])}
              >
                OBIE PATTERSON FOR MARYLAND STATE SENATE
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2018, TruBlu Politics
                developed a seven-piece mail program for Prince George’s County
                Councilman Patterson in his campaign for the open state senate
                seat in District 26. Our mail program highlighted that
                Councilman Patterson had the experience we could count on to
                create jobs, keep kids safe in schools, and increase access to
                affordable health care. <strong>Result</strong> Obie Patterson
                won a resounding victory with over 58 percent of the vote.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([firefightersImage1, firefightersImage2])}
              >
                INDEPENDENT EXPENDITURE FOR THE MONTGOMERY COUNTY CAREER FIREFIGHTERS ASSN., IAFF LOCAL 1664
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2018, TruBlu Politics
                developed a mail program for the Montgomery County Career
                Firefighters Association to support Marc Elrich in the
                Democratic Primary for County Executive. Our program
                communicated the message that Marc Elrich was the only candidate
                who had delivered for public safety. <strong>Result</strong> Our
                mail program was decisive in helping Marc Elrich emerge
                victorious in a six-way Democratic primary by 77 votes.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([debJungImage1, debJungImage2])}
              >
                DEB JUNG FOR HOWARD COUNTY COUNCIL
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2018, TruBlu Politics
                produced a five-piece mail program to bolster Deb Jung’s
                campaign for the open seat in Howard County Council District 4.
                Our mail communicated how she was the candidate the community
                could trust to properly fund schools, protect the environment
                and ensure development only moves forward with adequate public
                infrastructure. <strong>Result</strong> As a result of our
                communications program, Deb Jung defeated her closest opponent
                by 30 points, winning 63 percent of the vote.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([arianaKellyImage1,arianaKellyImage2])}
              >
                ARIANA KELLY FOR MARYLAND HOUSE OF DELEGATES
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2018, TruBlu Politics
                developed a four-piece mail plan for Ariana Kelly’s re-election
                campaign in a race where she was challenged by seven other
                candidates. The pieces we developed demonstrated Delegate
                Kelly’s achievements in improving education, combatting sexual
                assault and making health care more affordable.{" "}
                <strong>Result</strong> Our mail program helped Delegate Kelly
                achieve re-election to her third term representing District 16
                in the Maryland House of Delegates.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([seiuImage1, seiuImage2])}
              >
                SEIU LOCAL 1199
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2014, we produced direct
                mail for SEIU 1199 as part of their independent expenditure
                campaign in five legislative districts. The objective of the
                campaign was to elect progressive Democrats in competitive
                primaries in each district. <strong>Results</strong> Our direct
                mail was a deciding factor in numerous legislative victories. Of
                the 10 races we developed mail programs for, nine candidates won
                decisive victories.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([brianFroshImage1, brianFroshImage2])}
              >
                INDEPENDENT EXPENDITURE IN SUPPORT OF BRIAN FROSH FOR ATTORNEY GENERAL
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2014, TruBlu Politics
                produced a direct mail campaign supporting Brian Frosh for
                Attorney General. The program attacked his leading opponent over
                ethical, lack of attendance and poor voting record. The program
                also highlighted Frosh’s effectiveness and major support from
                key endorsers. <strong>Results</strong> Despite starting out the
                campaign trailing his opponent by double digits, our program
                helped Frosh win the Democratic Primary by almost 20 points.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([marylandDemImage1, marylandDemImage2])}
              >
                MARYLAND DEMOCRATIC PARTY COORDINATED CAMPAIGN
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2010, TruBlu Politics
                developed a four-piece mail program for the Maryland Democratic
                Party Coordinated Campaign. Two of the pieces were used to
                encourage Democrats to turn out to vote at early voting
                locations. Another two pieces were used to encourage Democrats
                to vote by mail. <strong>Results</strong> Aided by our program,
                the entire statewide Democratic ticket was re-elected by large
                margins including the re-election of Governor O’Malley against
                former Governor Robert Ehrlich.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([donnaEdwardsImage1, donnaEdwardsImage2])}
              >
                DONNA EDWARDS FOR CONGRESS
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> TruBlu Politics has been the
                direct mail consultant for Donna Edwards since 2008. In our
                first race, Donna Edwards was challenging 16-year incumbent
                Albert Wynn. The objective of our mail program was to
                demonstrate that Albert Wynn was out of touch with the district
                and position Donna as an attractive alternative. After that
                initial race, our objective was to show how Donna Edwards
                effectively represented the values and priorities of Maryland’s
                4th District. <strong>Results</strong> Our mail in 2008 helped
                Rep. Edwards defeat a 16-year incumbent in the Democratic
                Primary by more than 20 points. In her most recent race, we
                helped her secure large victories in both the Primary and
                General Elections.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([barbaraMikulskiImage1, barbaraMikulskiImage2])}
              >
                SENATOR BARBARA MIKULSKI
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2010, TruBlu Politics was
                hired as the direct mail consultant for Senator Barbara
                Mikulski. Our objective for the mail was to remind people of how
                effective Senator Mikulski has been in fighting for their
                interests in the U.S. Senate. For her campaign, we produced a
                five-piece mail program that emphasized her efforts to create
                good jobs and improve health care for Maryland families.{" "}
                <strong>Results</strong> Our mail helped Senator Mikulski win
                re-election with over 62 percent of the vote, helping her to
                become the longest serving woman Senator in U.S. history.
              </Typography>
              <hr />
              <Typography
                variant="h3"
                className={classes.sectionTitle}
                sx={{ mt: 8 }}
                onClick={() => handleOpen([frankKratovilImage1, frankKratovilImage2])}
              >
                FRANK KRATOVIL FOR CONGRESS
              </Typography>
              <Typography
                variant="body1"
                className={classes.paragraph}
                sx={{ my: 4 }}
              >
                <strong>Design Objective</strong> In 2008, we were hired as the
                direct mail consultant for Frank Kratovil. Our objective was to
                help elect Frank Kratovil to Congress in a very Republican
                district. To do so, we highlighted Frank’s moderate credentials
                while showing how Andy Harris deviated from key issues that
                mattered to the voters of Maryland’s 1st District.{" "}
                <strong>Results</strong> As a result of our work, we helped
                elect Frank Kratovil in a district that President Obama lost by
                19 points. While Rep. Kratovil was unable to overcome the
                Republican tide for re-election in 2010, we once again produced
                quality hard-hitting mail for his campaign.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>
            <img src={images[activeStep]} alt="Modal Content" className={classes.image} />
            <MobileStepper
              variant="dots"
              steps={images.length}
              position="static"
              activeStep={activeStep}
              className={classes.stepper}
              nextButton={
                <IconButton
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === images.length - 1}
                >
                  <KeyboardArrowRight />
                </IconButton>
              }
              backButton={
                <IconButton
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                </IconButton>
              }
            />
            <Button onClick={handleClose} sx={{ mt: 4, mb: 2 }}>Close</Button>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
};

export default Portfolio;
