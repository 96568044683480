import React from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import julieImage from "../../images/julie-large.jpg";

const useStyles = makeStyles((theme) => ({
  contentSection: {
    textAlign: "left",
    padding: "4rem 24rem!important",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const Julie = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xs={6}
          my={20}
          display={"flex"}
          alignItems="center"
          flexDirection={"column"}
        >
          <Avatar
            variant="square"
            alt="JULIE JAKOPIC
"
            src={julieImage}
            sx={{ width: "auto", height: "auto" }}
          />
          <Typography variant="h3" className={classes.name} my={6}>
            JULIE JAKOPIC
          </Typography>
          <Typography variant="h6" className={classes.position}>
            Senior Advisor
          </Typography>
          <Typography variant="body" py={2}>
            Julie Jakopic is the founder of iLead Strategies an organizational
            development firm helping leaders create resilient organizations and
            meaningful success in their lives. A professional change agent,
            Julie has shared the stage with luminaries like President Barack H.
            Obama and is a frequent commentator on leadership and women,
            appearing in or on CBS, NBC, Vanity Fair and Sirius XM. Her
            commitment to change includes community leadership such as leading
            Virginia’s List, a political action committee that helped add eleven
            new democratic women to the Virginia House of Delegates in 2017 and
            to flip the House and Senate in 2019.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Julie;
