import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Navbar from "./components/Navbar";
import Clients from "./components/Clients";
import OurTeam from "./components/OurTeam";
import ContactUs from "./components/ContactUs";
import Artemis from "./components/our-team/Artemis";
import Tapper from "./components/our-team/Tapper";
import Julie from "./components/our-team/Julie";
import DavidG from "./components/our-team/DavidG";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0073e6",
    },
    secondary: {
      main: "#e6007e",
    },
  },
  typography: {
    fontFamily: "Arial, sans-serif",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/ourteam" element={<OurTeam />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/team/artemis-queyquep" element={<Artemis />} />
          <Route path="/team/michael-tapper" element={<Tapper />} />
          <Route path="/team/julie-jakopic" element={<Julie />} />
          <Route path="/team/david-goodman" element={<DavidG />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
