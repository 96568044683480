import React from "react";
import { Grid, Typography, Box, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import headerImage from "../images/about_bg.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "10rem 16px 0",
    height: "60vh",
    textAlign: "center",
  },
  contentSection: {
    textAlign: "center",
    padding: theme.spacing(8, 2),
  },
  socialLinks: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(4),
    maxWidth: "600px",
    margin: "auto",
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
  },
  email: {
    marginBottom: theme.spacing(2),
  },
}));

const ContactPage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      display="flex"
      flexDirection={"column"}
      sx={{ width: "100%" }}
      alignContent={"center"}
    >
      <Grid item sx={{ width: "100%" }}>
        <Box className={classes.header}>
          <Grid
            container
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Typography
                variant="h3"
                sx={{ color: "white", fontWeight: "bold!important" }}
              >
                CONTACT US
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item display={"flex"} justifyContent={"center"}>
        <Box className={classes.contentSection}>
          <Grid container>
            <Grid item p={4}>
              <Typography variant="h6" className={classes.email} mb={2}>
                info@trublupolitics.com
              </Typography>
              <Typography variant="h5">
                Contact TruBlu Politics to find out how we can help you win!
              </Typography>
              <form className={classes.form} noValidate autoComplete="off">
                <TextField
                  label="Name"
                  variant="outlined"
                  className={classes.textField}
                  required
                  sx={{ my: 2 }}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  className={classes.textField}
                  required
                  type="email"
                  sx={{ my: 2 }}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  className={classes.textField}
                  required
                  multiline
                  rows={4}
                  sx={{ my: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  Send Message
                </Button>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ContactPage;
