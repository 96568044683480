import React from "react";
import { Grid, Typography,Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import artemisImage from "../../images/artemis-large.jpg";

const useStyles = makeStyles((theme) => ({
  contentSection: {
    textAlign: "left",
    padding: "4rem 24rem!important",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const Artemis = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xs={6}
          my={20}
          display={"flex"}
          alignItems="center"
          flexDirection={"column"}
        >
          <Avatar
            variant="square"
            alt="ARTEMIS QUEYQUEP"
            src={artemisImage}
            sx={{ width: "auto", height: "auto" }}
          />
          <Typography variant="h3" className={classes.name} my={6}>
            ARTEMIS QUEYQUEP
          </Typography>
          <Typography variant="h6" className={classes.position}>
            Partner
          </Typography>
          <Typography variant="body" py={2}>
            <b>Artemis Queyquep</b> is an expert in political analysis,
            management, and strategic communication. Known for remaining
            imperturbable during high-pressure situations, she adapts to the
            changing political landscape while keeping an intense focus on key
            objectives. Artemis assisted members on Capitol Hill, worked for
            national party committees, helped local candidates emerge victorious
            from obscurity, and led federal candidates to elevate their national
            profiles with great success.
          </Typography>
          <Typography variant="body" py={2}>
            Serving elected office holders, she oversaw transition teams,
            managed large budgets, and successfully used key relationships to
            codify meaningful proposals. As a political fundraiser, she has
            broken multiple records for candidate committees in Virginia, and
            North Carolina. She has led multiple candidates from historically
            marginalized backgrounds to excel in campaigning, fundraising, and
            entering party leadership.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Artemis;
