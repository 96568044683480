import React from "react";
import { Grid, Typography, Box, Avatar, Button, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import targetImage from "../images/home_bg.jpg";
import directMailImage from "../images/direct_mail.jpg";
import commsConsultingImage from "../images/comms_consulting.jpg";
import generalConsultingImage from "../images/general_consulting.jpg";
import caseStudiesBg from "../images/case_studies_bg.jpg"; 

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${targetImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: theme.spacing(8, 2),
    height: "67vh",
    textAlign: "center",
  },
  testimonialSection: {
    padding: theme.spacing(8, 0),
    textAlign: "center",
  },
  testimonial: {
    padding: theme.spacing(2),
    position: "relative",
    "&:before": {
      content: '"\\201c"',
      fontFamily: '"Times New Roman", Times, serif',
      display: "block",
      fontSize: "72px",
      lineHeight: "50px",
      fontWeight: 700,
      textAlign: "left",
      position: "static",
      color: "#8b8d9e",
    },
  },
  avatar: {
    margin: "auto",
    width: `${theme.spacing(13)} !important`,
    height: `${theme.spacing(13)} !important`,
  },
  quote: {
    margin: "4rem 0rem!important",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.6875",
    fontFamily: "'Open Sans', sans-serif",
    letterSpacing: "0em",
    color: "#8b8d9e",
    textAlign: "left",
  },
  name: {
    fontWeight: "bold",
  },
  position: {
    color: theme.palette.text.secondary,
  },
  servicesSection: {
    padding: theme.spacing(8, 0),
    textAlign: "center",
  },
  serviceItem: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  serviceImage: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  readMoreButton: {
    marginTop: theme.spacing(2),
    textTransform: "none!important",
    backgroundColor: "#005978!important",
    padding: "11px 30px!important",
    borderRadius: "24px!important",
    letterSpacing: "0.04em!important",
    borderWidth: "0!important",
  },
  caseStudiesSection: {
    backgroundImage: `url(${caseStudiesBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: theme.spacing(8, 2),
    textAlign: "left",
  },
  caseStudyItem: {
    padding: theme.spacing(2),
  },
  caseStudyTitle: {
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  caseStudyDescription: {
    color: "#e0e0e0",
  },
}));

const Home = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <div>
      <Box className={classes.header}>
        <Grid
          container
          mt={8}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Grid
            item
            mx={4}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            {!isMobile && <Typography variant="h3">THE PRINCIPALS OF</Typography>}
            <Typography variant="h1" color="primary">
              TRUBLU POLITICS
            </Typography>
            {!isMobile && <Typography variant="h3">HAVE BEEN HELPING</Typography>}
            {!isMobile && (
              <Typography
                variant="h2"
                color="secondary"
                className="header-wrap-text"
              >
                DEMOCRATIC CANDIDATES
              </Typography>
            )}
            {!isMobile && <Typography variant="h3">WIN SINCE 1996.</Typography>}
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        className={classes.testimonialSection}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        my={4}
      >
        <Typography variant="h4" gutterBottom sx={{ fontSize: "1rem" }} mb={4}>
          TESTIMONIALS
        </Typography>
        <Typography variant="h3">OUR CLIENTS</Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold" }}
          mb={4}
        >
          TRUST US
        </Typography>
        <Grid container display="flex" justifyContent="center">
          <Grid
            item
            xs={12}
            mx={3}
            md={3}
            className={classes.testimonial}
            sx={{
              border: "1px solid #e2e2e2",
              marginTop: "0",
              padding: "20px",
              borderRadius: "30px",
            }}
          >
            <Avatar
              alt="Michael O'Connor"
              src="/images/michael.jpg"
              className={classes.avatar}
            />
            <Typography variant="body1" className={classes.quote}>
              "This was the first campaign where I used the services of a firm
              like TruBlu Politics. Not having to add the stresses of designing,
              printing and mailing multiple pieces over the campaign’s final
              weeks allowed us to focus on our ground game. David and his team
              were professional throughout, responsive to our needs, and hit
              every deadline."
            </Typography>
            <Typography className={classes.name}>MICHAEL O’CONNOR</Typography>
            <Typography className={classes.position}>
              Mayor Frederick, MD
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            mx={3}
            md={3}
            className={classes.testimonial}
            sx={{
              border: "1px solid #e2e2e2",
              marginTop: "0",
              padding: "20px",
              borderRadius: "30px",
            }}
          >
            <Avatar
              alt="Meg Froelich"
              src="/images/meg.jpg"
              className={classes.avatar}
            />
            <Typography
              variant="body1"
              className={classes.quote}
              sx={{ mb: "10rem!important" }}
            >
              "TruBlu Politics is an integral part of my political organization.
              Their expertise, energy and thoughtfulness have been invaluable to
              my team."
            </Typography>
            <Typography className={classes.name}>MEG FROELICH</Typography>
            <Typography className={classes.position}>
              Colorado State Representative
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            mx={3}
            md={3}
            className={classes.testimonial}
            sx={{
              border: "1px solid #e2e2e2",
              marginTop: "0",
              padding: "20px",
              borderRadius: "30px",
            }}
          >
            <Avatar
              alt="David Sloan"
              src="/images/david.jpg"
              className={classes.avatar}
            />
            <Typography
              variant="body1"
              className={classes.quote}
              sx={{ mb: "8rem!important" }}
            >
              "TruBlu Politics was an integral part of the Maryland Democratic
              Party’s campaign for the Dream Act and Marriage Equality, as well
              as re-Election of President Obama and our Maryland Congressional
              Delegation."
            </Typography>
            <Typography className={classes.name}>DAVID SLOAN</Typography>
            <Typography className={classes.position}>
              Former Executive Director, Maryland Democratic Party
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        display="flex"
        justifyContent="center"
        my={6}
        p={6}
        flexDirection="column"
        sx={{
          background:
            "radial-gradient(at center, #FFF, #ffffffe8, #dddddd, #8d8d8ded)",
        }}
        alignItems="center"
      >
        <Grid item mb={4}>
          <Typography
            variant="h1"
            color="primary"
            sx={{ fontSize: "2.5rem!important" }}
          >
            EVALUATE. CREATE. MOTIVATE. WIN.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="p">
            THE PRINCIPLES OF TRUBLU POLITICS HAVE BEEN HELPING DEMOCRATIC
            CANDIDATES AND PROGRESSIVE ORGANIZATIONS SINCE 1996.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.servicesSection}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        my={4}
        p={6}
      >
        <Typography variant="h4" gutterBottom sx={{ fontSize: "1rem" }} mb={4}>
          SERVICES
        </Typography>
        <Typography variant="h3">HERE’S HOW WE CAN HELP</Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold" }}
          mb={4}
        >
          YOU WIN.
        </Typography>
        <Grid container display="flex" justifyContent="center" spacing={4}>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.serviceItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <img
              src={directMailImage}
              alt="Direct Mail"
              className={classes.serviceImage}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "left",
                fontSize: "24px!important",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "1.42",
                fontFamily: "Montserrat, sans-serif",
                letterSpacing: "0em",
              }}
            >
              DIRECT MAIL
            </Typography>
            <Typography variant="body1" className={classes.quote}>
              Our clients are our number one priority. A tailor-made strategic
              plan is created for each client with at least one member of
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.readMoreButton}
            >
              READ MORE
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.serviceItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <img
              src={commsConsultingImage}
              alt="Communications Consulting"
              className={classes.serviceImage}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "left",
                fontSize: "24px!important",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "1.42",
                fontFamily: "Montserrat, sans-serif",
                letterSpacing: "0em",
              }}
            >
              COMMUNICATIONS CONSULTING
            </Typography>
            <Typography variant="body1" className={classes.quote}>
              TruBlu Politics works with campaigns of all sizes to develop
              winning messages that are specifically tailored to their target
              audiences. We put
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.readMoreButton}
            >
              READ MORE
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.serviceItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <img
              src={generalConsultingImage}
              alt="General Consulting"
              className={classes.serviceImage}
            />
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                textAlign: "left",
                fontSize: "24px!important",
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "1.42",
                fontFamily: "Montserrat, sans-serif",
                letterSpacing: "0em",
              }}
            >
              GENERAL CONSULTING
            </Typography>
            <Typography variant="body1" className={classes.quote}>
              TruBlu Politics has decades of experience working with political-
              and issues-based campaigns. From the local to federal level,
              TruBlu has worked with
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.readMoreButton}
            >
              READ MORE
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        display="flex"
        justifyContent="center"
        my={6}
        p={6}
        flexDirection="column"
        sx={{
          background:
            "radial-gradient(at center, #FFF, #ffffffe8, #dddddd, #8d8d8ded)",
        }}
        alignItems="center"
      >
        <Grid item mb={4}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ fontSize: "1rem" }}
            mb={4}
          >
            ABOUT US
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "left",
              fontSize: "24px!important",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "1.42",
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "0em",
            }}
          >
            WHO WE ARE.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#8b8d9e",
            mb: 10,
          }}
        >
          We are a group of progressive professionals with decades of experience
          working with political – and issues – based campaigns. At TruBlu,
          we’re passionate about our work and our clients — we know that
          elections matter and we are on the front lines fighting for political
          and social change. Each election cycle is an opportunity to move the
          ball forward, and we’re dedicated to ensuring our clients are
          positioned to win at the ballot box.
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "left",
              fontSize: "24px!important",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "1.42",
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "0em",
            }}
          >
            WHAT WE DO.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#8b8d9e",
            maxWidth: "50vw",
            mb: 10,
          }}
        >
          As the one-stop shop for all your consulting needs, we offer
          individualized solutions designed around your campaign. We know there
          is not a “one-size fits all” model to campaigns and we work with our
          clients to create comprehensive and data driven strategies based on
          their specific needs. From General Consulting to direct-mail
          campaigns, we’re experts in identifying and executing the strategies
          you need to succeed.
        </Grid>
        <Grid item>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "left",
              fontSize: "24px!important",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "1.42",
              fontFamily: "Montserrat, sans-serif",
              letterSpacing: "0em",
            }}
          >
            HOW WE DO IT.
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "1.6875",
            fontFamily: "'Open Sans', sans-serif",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#8b8d9e",
            maxWidth: "50vw",
            mb: 10,
          }}
        >
          We think creatively. At TruBlu, we are the conduit between our clients
          and the tools and strategies they need to win. We take pride in
          delivering the right message to the right voters in a way that speaks
          simply and directly to their daily lives. As an award-winning
          Democratic direct mail firm, TruBlu Politics has decades of experience
          helping candidates across the country win their races – from City
          Council, to the U.S. Senate, and to every Office and Election in
          between.
        </Grid>
      </Grid>

      <Grid
        container
        className={classes.caseStudiesSection}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        my={4}
        p={6}
      >
        <Typography variant="h4" gutterBottom sx={{ fontSize: "1rem", color: "white" }} mb={4}>
          WHAT MAKES US THE BEST
        </Typography>
        <Typography variant="h3" sx={{color: "white"}}>CHECK OUT OUR</Typography>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", color: "white!important" }}
          mb={4}
        >
          CASE STUDIES
        </Typography>
        <Grid container display="flex" justifyContent="center" spacing={4}>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.caseStudyItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography variant="h5" className={classes.caseStudyTitle} sx={{color: "#ffff00!important", fontSize: "2rem!important", mb: 2}}>
              01/ BRIAN FROSH FOR ATTORNEY GENERAL
            </Typography>
            <Typography
              variant="body1"
              className={classes.caseStudyDescription}
            >
              <strong>Design Objective:</strong> In 2014, TruBlu Politics
              produced a direct mail campaign supporting Brian Frosh for
              Attorney General. The program attacked his leading opponent over
              ethical concerns, lack of attendance and poor voting record. The
              program also highlighted Frosh’s effectiveness and major support
              from key endorsers.
              <br />
              <br />
              <strong>Results:</strong> Despite starting out the campaign
              trailing his opponent by double digits, our program helped Frosh
              win the Democratic Primary by almost 20 points.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.caseStudyItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography variant="h5" className={classes.caseStudyTitle} sx={{color: "#ffff00!important", fontSize: "2rem!important", mb: 2}}>
              02/ FRANK KRATOVIL FOR CONGRESS
            </Typography>
            <Typography
              variant="body1"
              className={classes.caseStudyDescription}
            >
              <strong>Design Objective:</strong> In 2008, we were hired as the
              direct mail consultant for Frank Kratovil. Our objective was to
              help elect Frank Kratovil to Congress in a very Republican
              district. To do so we highlighted Frank’s moderate credentials
              while showing how Andy Harris was out of the mainstream on key
              issues that mattered to the voters of the 1st District.
              <br />
              <br />
              <strong>Results:</strong> As a result of our work, we helped elect
              Frank Kratovil in a district that President Obama lost by 19
              points. While Rep. Kratovil was unable to overcome the Republican
              tide in 2010, we once again produced quality hard hitting mail for
              his campaign.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            className={classes.caseStudyItem}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Typography variant="h5" className={classes.caseStudyTitle} sx={{color: "#ffff00!important", fontSize: "2rem!important", mb: 2}}>
              03/ SEIU LOCAL 1199
            </Typography>
            <Typography
              variant="body1"
              className={classes.caseStudyDescription}
            >
              <strong>Design Objective:</strong> In 2014, we produced direct
              mail for SEIU 1199 as part of their independent expenditure
              campaign to in 5 legislative districts. The objective of programs
              were to elect progressive Democrats in competitive primaries in
              each district.
              <br />
              <br />
              <strong>Results:</strong> Our direct mail was a decisive factor in
              numerous legislative victories. Of the 10 races we developed mail
              programs for, 9 candidates won decisive victories.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
