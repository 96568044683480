import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import headerImage from "../images/clients_bg.jpg";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundImage: `url(${headerImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: "10rem 16px 0",
    height: "60vh",
    textAlign: "center",
  },
  contentSection: {
    textAlign: "left",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  column: {
    paddingLeft: theme.spacing(4),
  },
  list: {
    listStyleType: "none",
    padding: 0,
  },
  listItem: {
    marginBottom: theme.spacing(2),
  },
}));

const Clients = () => {
  const classes = useStyles();

  return (
    <div>
      <Box className={classes.header}>
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
      
        >
          <Grid item>
            <Typography variant="h3" sx={{color: "white", fontWeight: "bold!important"}} >OUR CLIENTS</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.contentSection} p={4}>
        <Typography variant="h3" className={classes.sectionTitle}>
          NATIONAL
        </Typography>
        <Grid container >
          <Grid item xs={12} md={6} className={classes.column}>
            <ul className={classes.list}>
              <li className={classes.listItem}>21st Century Democrats</li>
              <li className={classes.listItem}>Democratic National Committee</li>
              <li className={classes.listItem}>Democratic Congressional Campaign Committee</li>
              <li className={classes.listItem}>NAACP</li>
              <li className={classes.listItem}>NAACP National Voter Fund</li>
              <li className={classes.listItem}>NARAL</li>
            </ul>
          </Grid>
        </Grid>
        
        <Typography variant="h3" className={classes.sectionTitle} sx={{ mt: 8 }}>
          FEDERAL
        </Typography>
        <Grid container >
          <Grid item xs={12} md={6} className={classes.column}>
            <ul className={classes.list}>
              <li className={classes.listItem}>IL 1- Bobby Rush for Congress</li>
              <li className={classes.listItem}>GA 12- John Barrow for Congress</li>
              <li className={classes.listItem}>MD 1- Frank Kratovil for Congress</li>
              <li className={classes.listItem}>MD 4- Donna Edwards for Congress</li>
              <li className={classes.listItem}>MD 8- Kweisi Mfume for Congress</li>
              <li className={classes.listItem}>MS 1- Bennie Thompson for Congress</li>
              <li className={classes.listItem}>NC 1- G.K. Butterfield for Congress</li>
              <li className={classes.listItem}>NH 1- Martha Fuller Clark for Congress</li>
              <li className={classes.listItem}>VA 8- Jim Moran for Congress</li>
              <li className={classes.listItem}>American Federation of Teachers IE for Ken Salazar for U.S. Senate (CO)</li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h3" className={classes.sectionTitle} sx={{ mt: 8 }}>
          STATE
        </Typography>
        <Grid container >
          <Grid item xs={12} md={6} className={classes.column}>
            <ul className={classes.list}>
              <li className={classes.listItem}>Jennifer Mello for Colorado State Senate</li>
              <li className={classes.listItem}>Brandon Shaffer for Colorado State Senate</li>
              <li className={classes.listItem}>Meg Froelich for Colorado State Representative</li>
              <li className={classes.listItem}>Progressive Majority, Colorado</li>
              <li className={classes.listItem}>Karen Middleton for Colorado Board of Education Fundraising</li>
              <li className={classes.listItem}>Kentucky Democratic Party</li>
              <li className={classes.listItem}>Al Carr for Maryland State Delegate</li>
              <li className={classes.listItem}>Kathleen Dumais for Maryland State Delegate</li>
              <li className={classes.listItem}>Brian Feldman for Maryland State Delegate</li>
              <li className={classes.listItem}>Jim Gilchrist for Maryland State Delegate</li>
              <li className={classes.listItem}>Sheila Hixson for Maryland State Delegate</li>
              <li className={classes.listItem}>John Hurson for Maryland State Delegate</li>
              <li className={classes.listItem}>Mila Johns for Maryland State Delegate</li>
              <li className={classes.listItem}>Anne Kaiser for Maryland State Delegate</li>
              <li className={classes.listItem}>Ariana Kelly for Maryland State Delegate</li>
              <li className={classes.listItem}>Clarence Lam for Maryland State Delegate</li>
              <li className={classes.listItem}>Jane Lawton for Maryland State Delegate</li>
              <li className={classes.listItem}>Kirill Reznik for Maryland State Delegate</li>
              <li className={classes.listItem}>Craig Rice for Maryland State Delegate</li>
              <li className={classes.listItem}>Carl Ward for Maryland State Delegate</li>
              <li className={classes.listItem}>Craig Zucker for Maryland State Delegate</li>
              <li className={classes.listItem}>Joanne Benson for Maryland State Senate</li>
              <li className={classes.listItem}>Rob Garagiola for Maryland State Senate</li>
              <li className={classes.listItem}>Delores Kelley for Maryland State Senate</li>
              <li className={classes.listItem}>Mike Lenett for Maryland State Senate</li>
              <li className={classes.listItem}>Rich Madaleno for Maryland State Senate</li>
              <li className={classes.listItem}>Victor Ramirez for Maryland State Senate</li>
              <li className={classes.listItem}>Jamie Raskin for Maryland State Senate</li>
              <li className={classes.listItem}>The District 39 Team (MD)</li>
              <li className={classes.listItem}>Marylanders for Integrity in Government IE for Brian Frosh for Maryland Attorney General</li>
              <li className={classes.listItem}>SEIU Local 1199 for IE for multiple Maryland legislative campaigns</li>
              <li className={classes.listItem}>Missouri Moms for Public Safety</li>
              <li className={classes.listItem}>Emily’s List IE for Claire McCaskill for MO Governor</li>
              <li className={classes.listItem}>American Federation of Teachers IE for Chris Gregoire for Governor (WA)</li>
            </ul>
          </Grid>
        </Grid>

        <Typography variant="h3" className={classes.sectionTitle} sx={{ mt: 8 }}>
          LOCAL
        </Typography>
        <Grid container >
          <Grid item xs={12} md={6} className={classes.column}>
            <ul className={classes.list}>
              <li className={classes.listItem}>Working Families for James Hahn for Mayor of Los Angeles, CA</li>
              <li className={classes.listItem}>Doug Milliken for Arapahoe County, CO Treasurer</li>
              <li className={classes.listItem}>Larry Beer for Aurora City, CO Council</li>
              <li className={classes.listItem}>Kathy Hartman for Jefferson County, CO Commissioner</li>
              <li className={classes.listItem}>Michael Carrigan for University of Colorado Regent</li>
              <li className={classes.listItem}>Mark Rodriguez for Annapolis, MD City Council</li>
              <li className={classes.listItem}>Alexus Viegas for Annapolis, MD City Council</li>
              <li className={classes.listItem}>Ross Arnett for Annapolis, MD Alderman</li>
              <li className={classes.listItem}>Andrew Pruski for Anne Arundel County, MD Council</li>
              <li className={classes.listItem}>Stephanie Rawlings-Blake for Baltimore City, MD Mayor</li>
              <li className={classes.listItem}>Michael O’Connor for Frederick, MD Mayor</li>
              <li className={classes.listItem}>Nancy Navarro for Montgomery County, MD Council</li>
              <li className={classes.listItem}>Montgomery County Career Firefighters IE for Marc Elrich for Montgomery County, MD Executive</li>
              <li className={classes.listItem}>Montgomery County, MD Democratic Party</li>
              <li className={classes.listItem}>Vote No Coalition, Montgomery County, MD</li>
              <li className={classes.listItem}>Derrick Davis for Prince George’s County, MD Council</li>
              <li className={classes.listItem}>Mel Franklin for Prince George’s County, MD Council</li>
              <li className={classes.listItem}>Kevin Harris for Prince George’s County, MD Council</li>
              <li className={classes.listItem}>Angela Alsobrooks for Prince George’s, MD State’s Attorney</li>
              <li className={classes.listItem}>Courtney Billups for Queen Anne’s County, MD Commissioner</li>
              <li className={classes.listItem}>Gene Ransom for Queen Anne’s County, MD Commissioner</li>
              <li className={classes.listItem}>Wendell Pribila for Ewing, NJ Mayor</li>
              <li className={classes.listItem}>Ewing Township, NJ, Democratic Committee</li>
              <li className={classes.listItem}>Douglas Palmer for Trenton, NJ Mayor</li>
              <li className={classes.listItem}>Paul Pintella for Trenton City, NJ Council</li>
              <li className={classes.listItem}>Manny Segura for Trenton City, NJ Council</li>
              <li className={classes.listItem}>Cordelia Staton for Trenton City, NJ Council</li>
              <li className={classes.listItem}>Brian Hughes for Mercer County, PA Executive</li>
              <li className={classes.listItem}>Anthony Williams for Washington, DC Mayor</li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Clients;
