import React from "react";
import { Grid, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import davidgImage from "../../images/davidg-large.jpg";

const useStyles = makeStyles((theme) => ({
  contentSection: {
    textAlign: "left",
    padding: "4rem 24rem!important",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const DavidG = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xs={6}
          my={20}
          display={"flex"}
          alignItems="center"
          flexDirection={"column"}
        >
          <Avatar
            variant="square"
            alt="DAVID GOODMAN"
            src={davidgImage}
            sx={{ width: "auto", height: "auto" }}
          />
          <Typography variant="h3" className={classes.name} my={6}>
            DAVID GOODMAN
          </Typography>
          <Typography variant="h6" className={classes.position}>
            President
          </Typography>
          <Typography variant="body" py={2}>
            David Goodman has 20 years of political experience as a consultant
            managing communications campaigns and field efforts for Democratic
            candidates, labor organizations and ballot initiatives across the
            country.
          </Typography>
          <Typography variant="body" py={2}>
            He has also been a general consultant and direct mail specialist for
            Democratic campaigns nationwide and played a leading role in the
            election of two new Democratic members of Congress in 2008. In 2010,
            David helped elect numerous legislators and defeated two incumbent
            state senators in Maryland. In 2014, David managed independent
            expenditure campaigns through SEIU Local 1199 that won 15 of 16
            target races. That same year, David managed a successful independent
            expenditure campaign that propelled Brian Frosh to victory as
            Maryland’s Attorney General. David has produced direct mail for
            winning campaigns from New Jersey to California.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default DavidG;
