import React from "react";
import { Grid, Typography,Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

import tapperImage from "../../images/tapper-lg.jpg";

const useStyles = makeStyles((theme) => ({
  contentSection: {
    textAlign: "left",
    padding: "4rem 24rem!important",
    margin: "auto",
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

const Tapper = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container display="flex" justifyContent="center">
        <Grid
          item
          xs={6}
          my={20}
          display={"flex"}
          alignItems="center"
          flexDirection={"column"}
        >
          <Avatar
            variant="square"
            alt="MICHAEL TAPPER"
            src={tapperImage}
            sx={{ width: "auto", height: "auto" }}
          />
          <Typography variant="h3" className={classes.name} my={6}>
          MICHAEL TAPPER
          </Typography>
          <Typography variant="h6" className={classes.position}>
          CEO
          </Typography>
          <Typography variant="body" py={2}>
          With more than 25 years of experience in communications, design and message development, Mike Tapper has worked extensively with both political and corporate clients. Mike has used his expertise in layered communications strategies to help elect Democratic candidates up and down the ballot. He has also developed successful campaigns that have increased turnout among infrequent voters.
          </Typography>
          <Typography variant="body" py={2}>
          Having managed large direct mail independent expenditure campaigns for clients such as the DNC, DCCC, Kentucky Democratic Party, and the NAACP, he has amassed an impressive collection of victories for countless campaigns across the country and first place Pollie Awards from the American Association of Political Consultants.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Tapper;
